class CommonBody {

  constructor() {
    this.common();
  }

  common() {
    //return console.log('Common');
    
  }

}

new CommonBody();
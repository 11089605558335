class Hoge {

  constructor() {
    if (Common.getName('app_form')) {
      this.app_form();
    } else if (Common.getName('Hoge_fuga')) {
      this.hoge_fuga();
    }
  }

  app_form() {
    $(function() {
      $(".form_item-btn-img").on("click",function(){
        $(".form_item-modal-box").toggleClass('active');
        $("#section-wrapper").toggleClass('active');
      });
    });
  }

  hoge_fuga() {
    return console.log('hoge_fuga');
  }

}

new Hoge();
class Index {

  constructor() {
    if (Common.getName('faq')) {
      this.faq();
    }
    if (Common.getName('result')) {
      this.result();
    }
  }

  faq() {
    $(function () {
      $(".js-accordion-box").on("click", function () {
        $(this).find(".js-accordion-content").slideToggle(300);
        $(this).toggleClass("open", 300);
      });
    });
  }

  result() {
    $(function () {
      $(".js-accordion-box").on("click", function () {
        $(this).find(".js-accordion-content").slideToggle(500);
        $(this).toggleClass("open", 500);
        /*$(window).scroll(function () {
          var selectors = $('.js-bar2');
          var scroll = $(window).scrollTop();
          selectors.each(function () {
            var position = $(this).offset().top - $(window).height();
            if (position < scroll) {
              $(this).addClass("slidein");
            }
          });
        });*/
      });
    });
    /*$(function () {
      $(window).scroll(function () {
        var selectors = $('.js-bar');
        var scroll = $(window).scrollTop();
        selectors.each(function () {
          var position = $(this).offset().top - $(window).height();
          if (position < scroll) {
            $(this).addClass("slidein");
          }
        });
      });
    });*/
  }
}

new Index();